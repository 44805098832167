/* eslint-disable max-statements */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import ViewportChecker from '@starbucks-web/pattern-library/lib/components/viewport-checker';
import Card from '@starbucks-web/pattern-library/lib/components/card';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import LoadingSpinner from '@starbucks-web/pattern-library/lib/components/loading-spinner';
import { variables } from '@starbucks-web/pattern-library/lib/shared-variables';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import { firstNameSelector } from 'shared/app/bundles/user';
import { PICKUP_OVERLAY_SEEN } from 'shared/app/state/action-creators/types';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import {
  currentSessionMostRecentOrderSelector,
  selectedPickupOptionNameSelector,
  orderPickupTimeSelector,
} from '../../state/selectors/ordering';
import {
  trackMopPostOrderDetailsClick,
  trackMopPostOrderWaitTimeView,
} from '../../state/track-event';

import PickupOverlay from '../order-pickup-overlay';
import { sharedCallsToAction } from '../../messages/ctas';
import messages from './messages';
import styles from './styles.cssm';

const orderPickupCardHeadingId = 'orderPickupCardHeadingId';

export const OrderPickupCard = ({ isHidden, cardContainerClass = '' }) => {
  if (isHidden) {
    return null;
  }

  const dispatch = useDispatch();
  const mostRecentOrder = useSelector(currentSessionMostRecentOrderSelector);
  const selectedPickupOptionName = useSelector(
    selectedPickupOptionNameSelector
  );
  const mostRecentOrderPickupTime = useSelector(orderPickupTimeSelector);
  const {
    orderPickupTimeLoading,
    orderPickupTime,
    orderPickupWaitTimeEstimateInSeconds,
    orderId,
    isPreOrderFallback,
  } = mostRecentOrderPickupTime ?? {};
  const firstName = useSelector(firstNameSelector);
  const configImagesUrl = useSelector(configImagesUrlSelector);

  const { openModal } = useModalContext();
  const { formatMessage } = useIntl();

  const orderPickupTimeMessage = () => {
    if (orderPickupTimeLoading) {
      return formatMessage(messages.orderPickupTimeLoading);
    }
    if (orderPickupTime) {
      return formatMessage(messages.orderPickupTime, {
        orderPickupTime,
      });
    }
    return formatMessage(
      firstName
        ? messages.orderPickupTimeGeneric
        : messages.orderPickupTimeGenericGuest,
      { firstName }
    );
  };

  const onClickHandler = () => {
    trackMopPostOrderDetailsClick();

    openModal({
      component: PickupOverlay,
      ariaLabelledBy: orderPickupCardHeadingId,
      onClose: () => dispatch({ type: PICKUP_OVERLAY_SEEN }),
    });
  };

  useEffect(() => {
    if (!orderPickupTimeLoading && orderPickupWaitTimeEstimateInSeconds) {
      trackMopPostOrderWaitTimeView({
        orderPickupWaitTimeEstimateInSeconds,
        orderId,
        isPreOrderFallback,
      });
    }
  }, [orderPickupTimeLoading, orderPickupWaitTimeEstimateInSeconds]);

  return (
    <ViewportChecker percentVisible={50} unobserveOnIntersection>
      {({ elementRef }) => {
        return (
          <div className="mb3" ref={elementRef}>
            <Card
              containerClasses={`${styles.cardContainer} ${cardContainerClass}`}
              contentClasses="py2 flex items-center justify-between"
              data-e2e="order-pickup-card"
            >
              <div className="ml3 flex items-center">
                {orderPickupTimeLoading ? (
                  <LoadingSpinner
                    accessibleLabel="NO_ARIA_LIVE"
                    backgroundColor={variables.colorWhite30}
                    className="mr2 mt2"
                    foregroundColor={variables.colorWhite}
                    size="32px"
                  />
                ) : (
                  <ImageLoader
                    alt=""
                    src={`${configImagesUrl}/service-wait-time/pickup-ready.png`}
                    width="40"
                  />
                )}
                <div
                  className="text-xs color-greenLight px2"
                  data-e2e="order-pickup-time"
                >
                  {selectedPickupOptionName &&
                    formatMessage(
                      messages[`orderPickupType${selectedPickupOptionName}`]
                    )}
                  <Heading
                    className="color-white text-bold"
                    id={orderPickupCardHeadingId}
                    size="sm"
                    tagName="h2"
                  >
                    {mostRecentOrder?.pricing?.store?.name ??
                      formatMessage(messages.orderPickupCardHeader)}
                  </Heading>
                  <p className="color-greenLight">{orderPickupTimeMessage()}</p>
                </div>
              </div>
              <Button
                className={`mr3 ${styles.detailsBtn}`}
                color="white"
                data-e2e="order-details-button"
                onClick={onClickHandler}
                visualStyle="positive"
              >
                {formatMessage(sharedCallsToAction.details)}
              </Button>
            </Card>
          </div>
        );
      }}
    </ViewportChecker>
  );
};

OrderPickupCard.propTypes = {
  isHidden: PropTypes.bool,
};

export default OrderPickupCard;
