import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import messages from '../messages';
import presentIcon from './present-icon';
import { trackGiftCardPostOrderCtaClick } from 'shared/app/state/track-event';

const SummaryPending = ({
  numberOfRecipients,
  checkAgainOnClick,
  deliveryMethod,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const isEmailDeliveryMethod = deliveryMethod?.toLowerCase() === 'email';
  const dispatch = useDispatch();

  const onClickHandler = () => {
    trackGiftCardPostOrderCtaClick('Check again');

    setButtonLoading(!buttonLoading);
    dispatch(checkAgainOnClick).then(() => {
      setButtonLoading(buttonLoading);
    });
  };

  return (
    <div className="text-center">
      <Icon
        className={buttonLoading ? 'color-black20' : 'color-textBlackSoft'}
        clipRule="evenodd"
        fillRule="evenodd"
        path={presentIcon}
        size="48px"
        viewBox="0 0 48 48"
      />
      <p className={`my3 ${buttonLoading ? 'color-textBlackSoft' : ''}`}>
        <FormattedMessage
          {...messages.orderPending}
          values={{ numberOfRecipients, isEmailDeliveryMethod }}
        />
      </p>
      {checkAgainOnClick ? (
        <Button
          loading={buttonLoading}
          onClick={onClickHandler}
          visualStyle="positive"
        >
          <FormattedMessage {...messages.checkAgainCta} />
        </Button>
      ) : null}
    </div>
  );
};

export default SummaryPending;
