import { useEffect, useState } from 'react';
import config from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURES } from 'shared/universal/optimizely-keys';
import { optimizelyUserIdSelector } from 'shared/app/state/selectors/optimizely';
import isApplePaySupported from 'shared/app/utils/is-apple-pay-supported';
import { CLEAR_CONTINUE_AS_GUEST } from 'shared/app/state/action-creators/types';

const optimizelyEnvironment = config.get('universal.optimizely.environment');

const useGcoV2Flag = (optimizely) => {
  const dispatch = useDispatch();

  const optimizelyUserId = useSelector(optimizelyUserIdSelector);
  const attributes = {
    environment: optimizelyEnvironment,
    'ux_exp_id': optimizelyUserId,
  };

  const [isGcoV2Enabled, setIsGcoV2Enabled] = useState(false);

  useEffect(() => {
    optimizely.onReady().then(() => {
      const isGcoV2FlagEnabled = optimizely.isFeatureEnabled(
        FEATURES.WEB_GCO_V2,
        optimizelyUserId,
        attributes
      );
      // This handles an edge case for unsupported Apple Pay browsers.
      // The scenario is when a user adds items to the cart, continues as a guest,
      // never completes the order, and the GCO CC/DC flag is turned off.
      // This allows the user to continue with the option to sign in or create an account from the cart.
      if (!isApplePaySupported && !isGcoV2FlagEnabled) {
        dispatch({ type: CLEAR_CONTINUE_AS_GUEST });
      }
      setIsGcoV2Enabled(isGcoV2FlagEnabled);
    });
  }, [optimizelyUserId]);

  return { isGcoV2Enabled };
};

export default useGcoV2Flag;
