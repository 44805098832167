/* eslint-disable complexity */
/* eslint-disable max-statements */
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import LoadingSpinner from '@starbucks-web/pattern-library/lib/components/loading-spinner';
import { variables } from '@starbucks-web/pattern-library/lib/shared-variables';

import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import {
  isMobileViewportSelector,
  viewportWidthSelector,
} from 'shared/app/bundles/viewport';
import { firstNameSelector, signedInSelector } from 'shared/app/bundles/user';
import { isCoreAppUrlSelector } from 'shared/app/state/selectors/locales';
import { getStaticMapUrl } from 'shared/app/utils/map-utils';

import {
  currentSessionMostRecentOrderSelector,
  selectedPickupOptionPathSelector,
  mostRecentOrderItemsSelector,
  orderPickupTimeSelector,
} from '../../state/selectors/ordering';

import HowToPickup from './how-to-pickup';
import OrderSummary from './order-summary';
import PickupImHereFrap from './pickup-im-here-frap';
import UpsellCard from '../upsell-card/upsell-card';

import orderPickupCardMessages from '../order-pickup-card/messages';
import messages from './messages';
import styles from './styles.cssm';
import { trackMopPostOrderView } from '../../state/track-event';

const PickupOverlay = () => {
  const { formatMessage } = useIntl();
  const { closeModal } = useModalContext();
  const ref = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const isCoreAppUrl = useSelector(isCoreAppUrlSelector);
  const isMobileViewport = useSelector(isMobileViewportSelector);
  const firstName = useSelector(firstNameSelector);
  const viewportWidth = useSelector(viewportWidthSelector);
  const signedIn = useSelector(signedInSelector);

  const mostRecentOrder = useSelector(currentSessionMostRecentOrderSelector);
  const orderItemsList = useSelector(mostRecentOrderItemsSelector);
  const selectedPickupOptionPath = useSelector(
    selectedPickupOptionPathSelector
  );
  const mostRecentOrderPickupTime = useSelector(orderPickupTimeSelector);

  const { isTipEnabled, orderId, store, summary } =
    mostRecentOrder?.pricing ?? {};
  const { guest } = mostRecentOrder?.orderStatus ?? {};
  const previousPickupOption = mostRecentOrder?.pickupOption;
  const {
    orderPickupTimeLoading,
    orderPickupTime,
    orderPickupTimeMin,
    orderPickupTimeMax,
    pickupOverlaySeen,
  } = mostRecentOrderPickupTime ?? {};

  useEffect(() => {
    trackMopPostOrderView();
  }, []);

  useEffect(() => {
    if (!orderItemsList || !store) {
      // This needs to be in an effect to avoid a setState warning
      // from React, because calling closeModal changes state
      // in the ModalProvider which is the parent of this Overlay
      closeModal();
    }
  }, [orderItemsList, store]);

  useEffect(() => {
    setContainerWidth(ref?.current?.offsetWidth ?? 0);
  }, [viewportWidth]);

  // If these two properties are missing, the previous
  // order data has been wiped.
  // This condition needs to be after the useEffects.
  // We want the first useEffect to call 'closeModal'
  // before we return null, even though the conditions
  // to do so are the same.
  if (!orderItemsList || !store) {
    return null;
  }

  let estimatePickupTimeMessage = formatMessage(
    messages.orderPickupTimeGeneric
  );

  if (pickupOverlaySeen && orderPickupTime) {
    estimatePickupTimeMessage = formatMessage(
      orderPickupCardMessages.orderPickupTime,
      { orderPickupTime }
    );
  }

  if (
    !pickupOverlaySeen &&
    orderPickupTime &&
    orderPickupTimeMin &&
    orderPickupTimeMax
  ) {
    estimatePickupTimeMessage = formatMessage(messages.orderPickupTime, {
      orderPickupTime,
      orderPickupTimeMin,
      orderPickupTimeMax,
    });
  }

  return (
    <OverlayWithCloseMessage>
      <ContentColumn className="pb3" size="md">
        <Heading className="text-bold" size="lg" tagName="h2">
          <FormattedMessage
            {...messages.pickupOverlayHeader}
            values={{ name: signedIn ? firstName : guest?.firstName }}
          />
        </Heading>
        <div className="flex items-center">
          {orderPickupTimeLoading && (
            <LoadingSpinner
              accessibleLabel="NO_ARIA_LIVE"
              backgroundColor={variables.colorBlack20}
              className="mt1 mr2"
              foregroundColor={variables.colorBlack}
              size="16px"
            />
          )}
          <p
            aria-live="polite"
            className="color-textBlackSoft text-xxs"
            data-e2e="overlay-pickup-time"
          >
            {orderPickupTimeLoading
              ? formatMessage(messages.orderPickupTimeLoading)
              : estimatePickupTimeMessage}
          </p>
        </div>
        {!signedIn && <UpsellCard containerClasses="mt3" />}

        <div className="mt3" ref={ref}>
          <div className="text-semibold pr1 text-sm">{store.name}</div>
          <div className="color-textBlackSoft flex-grow text-xs">
            {`${store.address.streetAddressLine1}, ${store.address.city}`}
          </div>

          <ImageLoader
            alt=""
            className={`mt2 ${styles.staticMap}`}
            includeFallback={{
              enabled: true,
              fallbackClassName: 'bg-grayNatural',
            }}
            src={getStaticMapUrl({
              width: containerWidth,
              height: '120',
              selectedStore: store,
            })}
          />
        </div>

        <OrderSummary
          closeModal={closeModal}
          isTipEnabled={isTipEnabled}
          orderAmount={summary?.price}
          orderItemsList={orderItemsList}
        />

        <HowToPickup
          name={signedIn ? firstName : guest?.firstName}
          option={previousPickupOption}
        />

        {isMobileViewport && (
          <PickupImHereFrap
            closeModal={closeModal}
            isCoreAppUrl={isCoreAppUrl}
            isSignedIn={signedIn}
            localMostRecentOrderStore={store}
            localMostRecentOrderToken={orderId}
            mostRecentOrder={mostRecentOrder}
            selectedPickupOptionPath={selectedPickupOptionPath}
          />
        )}
        {!signedIn && (
          <p className="color-textBlackSoft mt4">
            {formatMessage(messages.guestEmailMessage, {
              email: guest?.email,
            })}
          </p>
        )}
      </ContentColumn>
    </OverlayWithCloseMessage>
  );
};

export default PickupOverlay;
