import React, { createContext, useContext } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import { reAuthenticateRequest } from 'shared/app/bundles/user/state/action-creators/session';
import ReAuthDialog, {
  REAUTH_DIALOG_ID,
} from 'shared/app/bundles/user/components/re-auth-dialog';
import { hasShortSessionSelector } from 'shared/app/bundles/user/state/selectors/profile';
import { signedInSelector } from 'shared/app/bundles/user';
import { isCoreAppUrlSelector } from 'shared/app/state/selectors/locales';

export const SessionCheckContext = createContext({});

export const useSessionCheckContext = () => useContext(SessionCheckContext);

const SessionCheckProvider = ({ children }) => {
  const hasShortSession = useSelector(hasShortSessionSelector);
  const signedIn = useSelector(signedInSelector);
  const isCoreAppUrl = useSelector(isCoreAppUrlSelector);

  const { openModal, getMostRecentModal } = useModalContext();
  const dispatch = useDispatch();

  const checkReAuthRequired = ({ nextAction, onCancelRedirectUrl }) => {
    // For "guest/unauthenticated" users, we do not show the re-auth modal
    if (hasShortSession || !signedIn) {
      dispatch(nextAction());
      return;
    }

    const isMfaOverlayOpen = getMostRecentModal?.()?.id?.startsWith('mfa');
    if (isMfaOverlayOpen) {
      // The requested action is the `nextAction` function.
      // if MFA challenge is open, we return early without letting
      // the requested action proceed, as we need to complete MFA auth first.
      return;
    }

    const isReAuthDialogActive = getMostRecentModal?.()?.id === 'ReAuthDialog';
    if (isReAuthDialogActive) {
      return;
    }

    openModal({
      component: ReAuthDialog,
      ariaLabelledBy: REAUTH_DIALOG_ID,
      id: 'ReAuthDialog',
      componentProps: {
        onSuccess: () => dispatch(nextAction()),
        onCancel: () =>
          dispatch(() => {
            const redirectUrl = onCancelRedirectUrl || '/';
            isCoreAppUrl
              ? dispatch(push(redirectUrl))
              : (window.location.href = redirectUrl);
          }),
        reAuthenticate: (password, reputation) =>
          dispatch(reAuthenticateRequest(password, reputation)),
      },
    });
  };

  return (
    <SessionCheckContext.Provider
      value={{
        checkReAuthRequired,
      }}
    >
      {children}
    </SessionCheckContext.Provider>
  );
};

export default SessionCheckProvider;
