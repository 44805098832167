'use strict';

exports.FEATURES = {
  IN_APP_DELIVERY: 'in_app_delivery',
  CA_TAX_LABEL: 'ca_tax_label',
  MODIFIER_AVAILABILITY: 'modifier_availability',
  EMERGENCY_MOP_OUTAGE: 'emergency_mop_outage',
  WEB_GCO_V2: 'web_gco_cc_dc',
  WEB_GCO_MARKETING: 'web_gco_marketing',
};

exports.EXPERIMENTS = {};
